
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'assets/scss/custom';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$light-primary-text: white;
$light-secondary-text: white;


:root {
  --theme-primary-50 : 123;
  --theme-primary-100: 123;
  --theme-primary-200 : 123;
  --theme-primary-300 : 123;
  --theme-primary-400 : 123;
  --theme-primary-500 : 123;
  --theme-primary-600 : 123;
  --theme-primary-700 : 123;
  --theme-primary-800 : 123;
  --theme-primary-900 : 123;
  --theme-primary-A100: 123;
  --theme-primary-A200: 123;
  --theme-primary-A400: 123;
  --theme-primary-A700: 123;

  --theme-accent-50 : 123;
  --theme-accent-100: 123;
  --theme-accent-200 : 123;
  --theme-accent-300 : 123;
  --theme-accent-400 : 123;
  --theme-accent-500 : 123;
  --theme-accent-600 : 123;
  --theme-accent-700 : 123;
  --theme-accent-800 : 123;
  --theme-accent-900 : 123;
  --theme-accent-A100: 123;
  --theme-accent-A200: 123;
  --theme-accent-A400: 123;
  --theme-accent-A700: 123;

  }

$dynamic-theme-primary: (
  50 : var(--theme-primary-50),
  100 : var(--theme-primary-100),
  200 : var(--theme-primary-200),
  300 : var(--theme-primary-300),
  400 : var(--theme-primary-400),
  500 : var(--theme-primary-500),
  600 : var(--theme-primary-600),
  700 : var(--theme-primary-700),
  800 : var(--theme-primary-800),
  900 : var(--theme-primary-900),
  A100 : var(--theme-primary-A100),
  A200 : var(--theme-primary-A200),
  A400 : var(--theme-primary-A400),
  A700 : var(--theme-primary-A700),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);

$dynamic-theme-accent: (
  50 : var(--theme-accent-50),
  100 : var(--theme-accent-100),
  200 : var(--theme-accent-200),
  300 : var(--theme-accent-300),
  400 : var(--theme-accent-400),
  500 : var(--theme-accent-500),
  600 : var(--theme-accent-600),
  700 : var(--theme-accent-700),
  800 : var(--theme-accent-800),
  900 : var(--theme-accent-900),
  A100 : var(--theme-accent-A100),
  A200 : var(--theme-accent-A200),
  A400 : var(--theme-accent-A400),
  A700 : var(--theme-accent-A700),
  contrast: (
    50: $dark-secondary-text,
    100: $dark-secondary-text,
    200: $light-secondary-text,
    300: $dark-secondary-text,
    400: $light-secondary-text,
    500: $light-secondary-text,
    600: $light-secondary-text,
    700: $light-secondary-text,
    800: $light-secondary-text,
    900: $light-secondary-text,
    A100: $dark-secondary-text,
    A200: $light-secondary-text,
    A400: $light-secondary-text,
    A700: $light-secondary-text,
  )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$eckler-primary: mat.define-palette($dynamic-theme-primary);
$eckler-accent: mat.define-palette($dynamic-theme-accent, 500, A200, A700);

// The warn palette is optional (defaults to red).
$eckler-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$eckler-theme: mat.define-light-theme((
  color: (
    primary: $eckler-primary,
    accent: $eckler-accent,
    warn: $eckler-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($eckler-theme);

$custom-typography: mat.define-typography-config(
    $font-family: 'Poppins'
);

@include mat.typography-hierarchy($custom-typography);
@include mat.core;

@import '../node_modules/bootstrap/scss/bootstrap.scss';
html, body { height: 100%; }
body { margin: 0; font-family: Poppins, "Helvetica Neue", sans-serif !important; }

.mdc-text-field--outlined {
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-text-field-hover: 2px;
  --mdc-outlined-text-field-outline-width: 1px !important;
}

.mat-header-row, .mat-mdc-header-row {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
  font-family: Poppins !important;
}

.mat-mdc-raised-button:not(:disabled) {
  color: #fff !important;
}

.mat-mdc-form-field-hint {
  color: rgba(0, 0, 0, 0.6);
  font-size: 11px;
}

.mat-mdc-form-field-error {
  font-size: 11px;
}

.mat-mdc-unelevated-button {
  color: white !important;
}

.mat-mdc-text-field-wrapper {
  padding: 0 .75em 0 .75em !important;
  margin: .25em 0 !important;
}

.mat-dialog-title {
  font: 500 20px / 32px Poppins;
  letter-spacing: normal;
}

.mdc-data-table__table {
  min-width: 95% !important;
}

.mat-table, .mat-mdc-table {
  font-family: Poppins;
  border-radius: 10px !important;
  margin: 16px;
  margin-top: 0;
}

table tr:last-child td:last-child {
  border-bottom-right-radius:10px;
}

.mat-mdc-header-cell {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.54);
}

.cancel-button, .upload-icon {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: white !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.87) !important;
  display: block;
  padding: 0px 24px 24px 24px !important;
  max-height: 65vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-family: Poppins !important;
  font-size: 14px !important;
  letter-spacing: 0em !important;
}

.mdc-dialog__actions {
  box-sizing: content-box !important;
}

.mat-mdc-row, .mdc-data-table__content {
  font-family: Poppins !important;
}

.mat-mdc-form-field-infix {
  min-height: initial;
}

.mat-mdc-card {
  padding: 16px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 
              0px 6px 10px 0px rgba(0, 0, 0, 0.14), 
              0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;

}

.tooltip {
  background-color: #333;
  color: white;
  font: 400 10px / 15px Poppins;
  border-radius: 4px;
}

.nav-link {
  padding: 0.5rem 1rem;
}