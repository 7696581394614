@import './variables';


.logo{
    align-self: center;
    margin: 0 auto 20px;
    width: 216px;
    display: block;
}


.router-outlet ~ * {
    position: absolute;
    width: 100%;
}

.mat-table{
    border-radius: $border-radius;
    margin:16px;
    margin-top: 0;

// .mat-header-row, .mat-mdc-header-row {
//     border-top-left-radius: $border-radius;
//     border-top-right-radius: $border-radius;
//     font-family: Poppins !important;
// }

.mat-row{
    &:last-of-type{
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}

}

.sidebardetailContainer{
    height:calc(100vh - 40px)!important;
    width:100%;
}


.title{
    margin:20px 0 0 8px !important;
    font: 700 24px / 32px Poppins, "Helvetical Neue", sans-serif !important;
}

.dialogTitle{
    font: 700 24px / 32px Poppins, "Helvetical Neue", sans-serif !important;
    padding: 24px 24px 0px 24px;
}

.square{
    margin-left:5px !important;
    height: 27px;
    width: 27px !important;
    padding: 0px;
    border: 1px;
}

.withoutformfield{
    margin-bottom:21px;
}

.mat-mini-fab{
    color:#ffffff;
    &.mat-accent{
        color: #fff !important;
    }
}

.mat-cell{
    overflow: visible;
}

.errorMessage {
    background-color: $warning !important;
    color: #fff !important;
    .mat-simple-snackbar-action{
        color: #fff;
    }
}

.validMessage {
    background-color: $valid !important;
    color: #fff !important;
    .mat-simple-snackbar-action{
        color: #fff;
    }
}

.mat-snack-bar-container {
    max-width: 100% !important;
}

.row{
    --bs-gutter-x:0 !important;
}

mat-spinner{
    transform:scale(50%);
    margin:0 auto;
}

mat-chip{
    color:#fff !important;
}


.breacrumbs{
    height: 40px !important;
    font-size:12px !important;

    a{
        color:rgba(0,0,0,0.5);
        padding: 0 5px;
        text-decoration: none;

         
        &:hover{
           color:rgba(0,0,0,0.5);
           background-color: rgba(0,0,0,0.1);
            border-radius: 4px;
        }
    }
    
}

.mat-row:nth-child(even), .mat-mdc-row:nth-child(even){
    background-color: #fff;
}
        
.mat-row:nth-child(odd),.mat-mdc-row:nth-child(odd){
    background-color: rgb(248,248,248);
}

.force-link{
    text-decoration: underline;
    color: #0D6EFC;

    &:hover{
        color:#0a58ca;

    }
}